import { useListingFilters, SORT_OPTION_VALUES } from "@app/hooks/useListingFilters"
import { useState, useCallback, useMemo, useEffect } from "react"
import { useCustomerContext } from "@app/providers/customer"
import { useCore } from "@app/hooks/useCore"
import { useCart } from "@app/hooks/useCart"
import { useShopify, useShopifyProductRaw } from "@app/hooks/useShopify"
import { useConfigContext } from "@app/providers/config"
import { useBrand } from "@app/hooks/useBrand"

import type { Option } from "@components/Select"

const ITEMS_PER_PAGE = 25

const sortOptions = [
  { label: "A-Z", value: SORT_OPTION_VALUES.A_TO_Z },
  { label: "Z-A", value: SORT_OPTION_VALUES.Z_TO_A },
  // { label: "Price: Low to High", value: SORT_OPTION_VALUES.PRICE_LOW_HIGH },
  // { label: "Price: High to Low", value: SORT_OPTION_VALUES.PRICE_HIGH_LOW },
]

const useWishList = ({ rawProducts }: { rawProducts: any }) => {
  const { getProducts } = useShopify()
  const { getRawProduct } = useShopifyProductRaw()
  // State Setters
  const [loading, setLoading] = useState<boolean>(false)
  const [products, setParsedProducts] = useState<any>(null)
  const [activeSortOption, setActiveSortOption] = useState(sortOptions[0])
  const [activeFilterOptions, setActiveFilterOptions] = useState<any>(null)
  const [humanCurrentPageNumber, setHumanCurrentPageNumber] = useState(1)
  const [addToCartLoading, setAddToCartLoading] = useState<string>("")

  const getParsedProducts = useCallback(async () => {
    const handles = rawProducts?.map(product => product?.shopify?.handle)?.filter(p => p)

    const rawShopifyProducts = handles?.length ? await getProducts({ firstImages: 2, firstVariants: 10, handles: handles }) : rawProducts
    const productsAvailable = rawShopifyProducts?.filter((p: any) => p?.id)

    await setParsedProducts(productsAvailable)
    setLoading(false)
  }, [getProducts, rawProducts])

  useEffect(() => {
    setLoading(true)
    if (products) setParsedProducts(null)
    if (rawProducts) getParsedProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawProducts])

  // External hook calls
  const { customer } = useCustomerContext()
  const { addToCartMultiple } = useCart()

  const {
    helpers: { capitalise },
  } = useCore()
  const { getBrand } = useBrand()

  // Function defs
  const getUniqueProductCategories = useCallback(() => {
    const allProductCategoryTags = products?.map((product: any) => product?.tags?.filter((tags: any) => tags?.startsWith("category:")))
    const flattenedTags = allProductCategoryTags?.flat()
    const uniqueTags = [...new Set(flattenedTags)]?.filter(tag => tag)
    const tagsWithoutCategory = uniqueTags?.map((tag: any) => tag?.replace("category:", ""))
    const mappedTags = tagsWithoutCategory?.map((tag: any) => ({ key: tag, checked: false }))

    return mappedTags
  }, [products])

  const getUniqueProductTypes = useCallback(() => {
    const productTypes = products?.map((product: any) => {
      // For swym wishlist product
      if (product?.productType) return product?.productType?.toLowerCase()
      // For regular sanity product from curated list
      if (product?.shopify) {
        const rawShopifyProduct = getRawProduct(product)
        return rawShopifyProduct?.product?.productType?.toLowerCase()
      }
      return null
    })

    const uniqueProductTypes = [...new Set(productTypes)]?.filter(type => type)

    const mappedProductTypes = uniqueProductTypes?.map((productType: string) => ({
      key: productType,
      checked: false,
    }))

    return mappedProductTypes
  }, [products, getRawProduct])

  const getUniqueBrands = useCallback(() => {
    const productBrands = products?.map((product: any) => getBrand({ product })?.brandHandle)
    const nonFalsyProductBrands = productBrands?.filter(brand => brand)
    const uniqueProductBrands = [...new Set(nonFalsyProductBrands)]
    const mappedBrands = uniqueProductBrands?.map((brand: string) => ({ key: brand, checked: false }))

    return mappedBrands
  }, [getBrand, products])

  const filterOptions = useMemo(
    () => [
      {
        name: "Food Type",
        id: "b21f64b7-8f33-42c9-95d6-27674f2e52fb",
        options: getUniqueProductCategories(),
      },
      {
        name: "Product",
        id: "97f30468-18b6-4052-88e1-e8dfd7198371",
        options: getUniqueProductTypes(),
      },
      {
        name: "Brands",
        id: "c0d8df3d-b23c-491a-bbea-b9d51beae36c",
        options: getUniqueBrands(),
      },
    ],
    [getUniqueProductCategories, getUniqueProductTypes, getUniqueBrands]
  )

  const handleSortChange = useCallback((option: Option) => setActiveSortOption(option), [])

  const handleFilterChange = useCallback(
    (selectedOption: string, type: string) => {
      const newState = activeFilterOptions?.map(filter => {
        if (filter?.name === type) {
          const newOptions = filter?.options?.map((option: any) => {
            if (option?.key === selectedOption) {
              return {
                key: option?.key,
                checked: !option?.checked,
              }
            } else {
              return option
            }
          })

          return {
            ...filter,
            options: newOptions,
          }
        } else {
          return filter
        }
      })

      setActiveFilterOptions(newState)
    },
    [activeFilterOptions]
  )

  const { filterItemsBySortOption, filterItemsByFilterOption, filterItemsByPagination } = useListingFilters()
  const { itemsFilteredBySortOption } = filterItemsBySortOption({ items: products, activeSortOption })
  const { itemsFilteredByFilterOptions } = filterItemsByFilterOption({ items: itemsFilteredBySortOption, activeFilterOptions })
  const { itemsFilteredByPagination } = filterItemsByPagination({
    items: itemsFilteredByFilterOptions,
    humanCurrentPageNumber,
    itemsPerPage: ITEMS_PER_PAGE,
  })
  const filteredItems = useMemo(() => itemsFilteredByPagination, [itemsFilteredByPagination])

  // replaces {firstName} susbtring with customers first name
  const replaceFirstName = useCallback(
    (text?: string) => {
      if (!text || !customer) return { parsedText: "" }
      const parsedText = text?.replace("{firstName}", capitalise(customer?.firstName))
      return { parsedText }
    },
    [capitalise, customer]
  )

  const handleAddAllToCart = useCallback(
    async ({ products, listToDisplay, listId = "" }: { products?: any; listToDisplay: any; listId: string }) => {
      setAddToCartLoading(listId)
      const handles = products?.map((product: any) => product?.handle)?.filter((p: any) => p)
      const listToMap = handles?.length ? await getProducts({ firstVariants: 10, handles }) : listToDisplay?.products

      if (listToMap) {
        const mappedListProducts = await listToMap?.map((product: any) => {
          const rawShopifyProduct = getRawProduct(product)

          const variantId = rawShopifyProduct?.product?.variants?.find(
            (variant: any) => variant?.availableForSale || !variant?.currentlyNotInStock
          )?.id

          return {
            quantity: 1,
            customAttributes: [],
            variantId,
          }
        })
        const availableItems = mappedListProducts?.filter((variant: any) => variant?.variantId)

        if (availableItems?.length) {
          await addToCartMultiple(availableItems)
          setAddToCartLoading("")
        }
      }
    },
    [addToCartMultiple, getProducts, getRawProduct]
  )

  const handleFilterReset = useCallback(() => {
    setActiveFilterOptions(filterOptions)
  }, [filterOptions])

  useEffect(() => {
    if (!activeFilterOptions && products) {
      setActiveFilterOptions(filterOptions)
    }
  }, [products, activeFilterOptions, filterOptions])

  const handlePageChange = useCallback(num => num !== "..." && setHumanCurrentPageNumber(num), [])

  const handleNextPage = useCallback(() => setHumanCurrentPageNumber(humanCurrentPageNumber + 1), [humanCurrentPageNumber])

  const handlePreviousPage = useCallback(() => setHumanCurrentPageNumber(humanCurrentPageNumber - 1), [humanCurrentPageNumber])

  const totalPages = useMemo(() => Math.ceil(itemsFilteredByFilterOptions?.length / ITEMS_PER_PAGE), [itemsFilteredByFilterOptions?.length])

  const createPaginationOptions = useCallback(() => {
    return {
      totalPages,
      humanCurrentPageNumber,
      handlePageChange,
      handleNextPage,
      handlePreviousPage,
      hasPreviousPage: humanCurrentPageNumber !== 1,
      hasNextPage: humanCurrentPageNumber !== totalPages,
    }
  }, [humanCurrentPageNumber, handlePageChange, handleNextPage, handlePreviousPage, totalPages])

  const paginationOptions = useMemo(() => createPaginationOptions(), [createPaginationOptions])

  return {
    sortOptions,
    activeSortOption,
    handleSortChange,
    filteredItems,
    replaceFirstName,
    handleAddAllToCart,
    addToCartLoading,
    filterOptions: activeFilterOptions,
    handleFilterChange,
    handleFilterReset,
    setActiveFilterOptions,
    paginationOptions,
    products,
    loading,
  }
}

const useWishListNormaliser = () => {
  const { store } = useConfigContext()
  const {
    helpers: { encodeShopifyId },
  } = useCore()

  // Removes the https://{shopifyDomain} from the swym product url
  const normaliseProductLink = useCallback(
    (link: string) => link?.replace(`https://${store?.shopifyShopDomain}`, ""),
    [store?.shopifyShopDomain]
  )

  const getHandle = useCallback(
    link => {
      const linkParts = normaliseProductLink(link)?.split("/")
      const handle = linkParts?.[linkParts?.length - 1]
      return handle
    },
    [normaliseProductLink]
  )

  const normaliseWishList = useCallback(
    ({ list }: { list: SwymList }) => {
      return {
        title: list?.lname,
        products: list?.listcontents?.map((product: SwymProduct) => ({
          // ...product,
          title: product?.dt,
          image: product?.iu,
          link: {
            url: normaliseProductLink(product?.du),
          },
          id: encodeShopifyId(product?.empi, "Product"),
          tags: product?.cprops?.productTags,
          productType: product?.cprops?.productType,
          variants: [
            {
              priceV2: {
                amount: product?.pr,
              },
              id: encodeShopifyId(product?.epi, "ProductVariant"),
              availableForSale: product?.cprops?.availableForSale,
            },
          ],
          handle: getHandle(product?.du),
        })),
        _key: list?.lid,
      }
    },
    [encodeShopifyId, normaliseProductLink, getHandle]
  )

  return { normaliseWishList }
}

export { useWishList, useWishListNormaliser }
